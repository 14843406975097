import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Gender} from 'dehimer-relatives-tree/lib/types'
import {useIntl} from 'react-intl'

import {OverlayTrigger, Tooltip} from 'react-bootstrap'

import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'

import useTreeStyles from '@hooks/useTreeStyles'
import useSessionTs from '@hooks/useSessionTs'
import useHostBasedUrl from '@hooks/useHostBasedUrl'
import useRelationsMap from '@hooks/useRelationsMap'
import useHighlightParents from '@components/organisms/Tree/hooks/useHighlightParents'
import useBlockContextMenu from '@components/organisms/Tree/hooks/useBlockContextMenu'
import useHover from '@components/organisms/Tree/hooks/useHover'
import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'

import EditButtons from './components/EditButtons'
import SubTreeButton from '@components/atoms/SubTreeButton'
import Cult from '@components/organisms/Tree/components/Node/components/Cult'

import {NODE_AVATAR_SIZE, NODE_HEIGHT, RelationsEnum} from '@components/organisms/Tree/constants'

import {PersonModel, TreeModel} from '@api/models'
import {relationNamesMap} from '@components/molecules/PersonsModal'

type Partner = {
  id: number
  status: RelationsEnum
  avatar: string
  gender: Gender
  name?: string
  parents?: number[]
  person_id?: number
  look_id?: number
}

type PartnerProps = {
  ownerId: number
  partner: Partner
  treeId?: TreeModel['id']
  size: number
  editMode: boolean
  withPadding: boolean
  subTreeOwner?: PersonModel
  readonly?: boolean
  hideCult?: boolean
  hideSubTree?: boolean
}
const Partner = ({
  ownerId,
  partner,
  size,
  editMode,
  withPadding,
  treeId,
  subTreeOwner,
  readonly,
  hideCult,
  hideSubTree,
}: PartnerProps) => {
  // console.log('### Partner.partner', partner)

  const {hover, onMouseOver, onMouseLeave} = useHover()

  const {show: showSimDrawer} = useSimDrawerStore()
  const handleNodePress = (partner: Partner) => (e) => {
    if (partner.id && !editMode) {
      showSimDrawer(partner.id, {allowSharing: !subTreeOwner})
    } else {
      e.preventDefault(true)
      e.stopPropagation()
    }
  }

  const clickable = !editMode && typeof partner.id !== 'undefined'

  const firstLetters = useFirstLetters(partner.name)

  const highlight = useHighlightParents(partner, false, editMode)
  const ref = useBlockContextMenu()

  const {
    avatarBorderColor,
    avatarBorderWidth = 2,
    avatarBorderRadius,
    avatarBorderType,
  } = useTreeStyles(treeId)
  const avatarBorderWidthParsed = parseInt(`${avatarBorderWidth}`)
  const avatarBorderRadiusParsed = (size / NODE_HEIGHT) * parseInt(`${avatarBorderRadius}`)

  const padding = withPadding ? 5 : 0

  const {ts} = useSessionTs()
  const avatarUrl = useHostBasedUrl(partner.avatar)
  const avatar = useMemo(() => {
    if (avatarUrl) {
      return <AvatarBackground src={`${avatarUrl}?ts=${ts}`} />
    }

    if (firstLetters) {
      return <Initials $size={size}>{firstLetters}</Initials>
    }

    return <AvatarBackground src={'https://my-engine.ru/modules/users/avatar.png'} />
  }, [avatarUrl, firstLetters, ts, size])

  const relationsMap = useRelationsMap()
  // console.log('### relationsMap', relationsMap)
  const relation = relationsMap?.[partner.status]
  // console.log('### relation', relation)
  const relationIconUrl = relation?.file?.url || relation?.base64
  // console.log('### relationIconUrl', relationIconUrl)

  const intl = useIntl()
  const relationName = useMemo(() => {
    // console.log('### relationNamesMap', partner.status, relationNamesMap[partner.status])
    if (relationNamesMap[partner.status])
      return intl.formatMessage({id: relationNamesMap[partner.status]})
    if (relation) return relation.name_en || relation.name_ru || relation.status

    return partner.status
  }, [intl, relation, partner])

  return (
    <Wrapper
      id='kt_sim_toggle'
      ref={ref}
      $size={size}
      $clickable={clickable}
      $highlight={highlight}
      $padding={padding}
      onClick={handleNodePress(partner)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onDragStart={(e) => e.preventDefault()}
    >
      <OverlayTrigger
        placement='top'
        delay={{show: 50, hide: 400}}
        overlay={(props) => (
          <Tooltip id='button-tooltip' {...props}>
            {relationName}
          </Tooltip>
        )}
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
      >
        <RelationWrapper $size={size}>
          {relationIconUrl ? <RelationIcon src={relationIconUrl} /> : null}
        </RelationWrapper>
      </OverlayTrigger>

      <RightPartWrapper>
        <AvatarWrapper
          $size={size}
          $avatarBorderColor={avatarBorderColor}
          $avatarBorderWidth={avatarBorderWidthParsed}
          $avatarBorderRadius={avatarBorderRadiusParsed}
          $avatarBorderType={avatarBorderType as string}
          $padding={padding}
        >
          <Avatar
            $size={size}
            $avatarBorderColor={avatarBorderColor}
            $avatarBorderWidth={avatarBorderWidthParsed}
            $avatarBorderRadius={avatarBorderRadiusParsed}
            $avatarBorderType={avatarBorderType as string}
            $padding={padding}
          >
            {avatar}
          </Avatar>

          {!hideCult && partner.look_id && treeId ? (
            <Cult size={size} lookId={partner.look_id} treeId={treeId} />
          ) : null}

          {!hideSubTree ? (
            <SubTreeButton
              personId={partner.id}
              treeId={treeId}
              hover={hover}
              nodeSize={size}
              avatarBorderWidthParsed={avatarBorderWidthParsed}
              editMode={editMode}
            />
          ) : null}
        </AvatarWrapper>

        {editMode ? (
          <EditWrapper $size={size} $avatarBorderWidth={avatarBorderWidthParsed}>
            <EditButtons
              ownerId={ownerId}
              person_id={partner.id}
              hover={hover}
              size={size - avatarBorderWidthParsed * 2}
              relationType={partner.status}
            />
          </EditWrapper>
        ) : null}
      </RightPartWrapper>
    </Wrapper>
  )
}

export default React.memo(Partner)

const Wrapper = styled.div<{
  $size: number
  $clickable: boolean
  $highlight: boolean
  $padding: number
  $debugColor?: string
}>`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  height: ${({$size}) => $size}px;

  margin-left: -${NODE_HEIGHT / 2}px;
  padding: ${({$padding}) => $padding}px;
  padding-right: 0;
  padding-left: 0;

  cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'initial')};

  position: relative;

  opacity: ${({$highlight}) => ($highlight ? 1 : 0.4)};
  transition: opacity 300ms;

  background-color: #${({$debugColor}) => $debugColor};
`

const RightPartWrapper = styled.div<{$debugColor?: string}>`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: #${({$debugColor}) => $debugColor};
`

const AvatarWrapper = styled.div<{
  $size: number
  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
  $padding: number
}>`
  width: ${({$size, $padding}) => $size - $padding * 2}px;
  height: ${({$size, $padding}) => $size - $padding * 2}px;

  position: relative;
`

const Avatar = styled.div<{
  $size: number
  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
  $padding: number
}>`
  display: flex;

  width: ${({$size, $padding}) => $size - $padding * 2}px;
  height: ${({$size, $padding}) => $size - $padding * 2}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius / 2}px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  overflow: hidden;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Initials = styled.div<{$size: number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${({$size}) => $size * 0.3}px;
  color: #fff;
  font-weight: 500;
`

const EditWrapper = styled.div<{$size: number; $avatarBorderWidth: number}>`
  position: absolute;
  left: 0;
  top: 0;

  height: ${({$size, $avatarBorderWidth}) => $size - $avatarBorderWidth * 2}px;
  width: ${({$size, $avatarBorderWidth}) => $size - $avatarBorderWidth * 2}px;

  border-radius: 50%;
  box-sizing: border-box;
`

const RELATION_SIZE = 20
const MIN_RELATION_SIZE = 12
const RelationWrapper = styled.div<{$size: number}>`
  min-width: ${({$size}) =>
    Math.max(MIN_RELATION_SIZE, (RELATION_SIZE * $size) / NODE_AVATAR_SIZE)}px;
  width: ${({$size}) => Math.max(MIN_RELATION_SIZE, (RELATION_SIZE * $size) / NODE_AVATAR_SIZE)}px;
  height: ${({$size}) => Math.max(MIN_RELATION_SIZE, (RELATION_SIZE * $size) / NODE_AVATAR_SIZE)}px;
  margin-right: 20px;
  margin-left: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const RelationIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
