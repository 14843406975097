import {create} from 'zustand'
import {AvatarModel} from '@api/models'
import {createCult, deleteCult, loadCults, updateCult} from '@api/looks'
import {createTag, loadTags, updateTag} from "@api/tags";

export type Tag = {
    id?: number
    name: string
}

type TagsStore = {
    loading: boolean
    tags?: Tag[]
    loadTags: () => void
    createTag: (params: Tag) => void
    updateTag: (cultId: number, params: Tag) => void
    deleteTag: (cultId: number) => void
    updateTags: (tags: Tag[]) => void
}

export const useTagsStore = create<TagsStore>((set) => ({
    loading: false,
    tags: [],
    loadTags: () => {
        set((state) => ({...state, loading: true}))

        loadTags()
            .then((response) => {
                set((state) => {
                    const tags = response.data

                    const newTagIds = tags.map(({id}) => id)
                    return {
                        ...state,
                        tags: [...(state.tags ?? [])?.filter(({id}) => !newTagIds.includes(id)), ...tags],
                    }
                })
            })
            .finally(() => {
                set((state) => ({...state, loading: false}))
            })
    },
    createTag: (params: Tag) => {
        set((state) => ({...state, loading: true}))

        createTag(params)
            .then((response) => {
                console.log('### createCult.response', response)
                set((state) => ({...state, tags: [...(state.tags || []), response.data]}))
            })
            .finally(() => {
                set((state) => ({...state, loading: false}))
            })
    },
    updateTag: (tagId, params) => {
        set((state) => ({...state, loading: true}))
        updateTag(tagId, params)
            .then((response) => {
                set((state) => {
                    const tags = [...(state.tags || [])]
                    const foundIndex = tags.findIndex(({id}) => id === tagId)
                    tags[foundIndex] = response.data
                    return {...state, tags: tags}
                })
            })
            .finally(() => {
                set((state) => ({...state, loading: false}))
            })
    },
    deleteTag: (tagId) => {
        set((state) => ({...state, loading: true}))
        deleteCult(tagId)
            .then(() => {
                set((state) => ({...state, tags: state.tags?.filter(({id}) => id !== tagId)}))
            })
            .finally(() => {
                set((state) => ({...state, loading: false}))
            })
    },
    updateTags: (tags) => {
        console.log('### updateCults', tags)

        set((state) => {
            const newTagIds = tags.map(({id}) => id)
            return {
                ...state,
                tags: [...(state.tags ?? [])?.filter(({id}) => !newTagIds.includes(id)), ...tags],
            }
        })
    },
}))
