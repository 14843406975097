import React, {useMemo} from 'react'
import styled from 'styled-components'
// import '@_metronic/assets/sass/components/_tooltip.scss'

import ErrorBoundary from '@components/atoms/ErrorBoundary'
import NodeInfo from '@components/organisms/Tree/components/Node/components/NodeInfo'
import Partner from './Partner'

import {NODE_AVATAR_SIZE, NODE_HEIGHT} from '@components/organisms/Tree/constants'

export const partnerHeightBasedOnCount = (partnersCount: number) => {
  if (partnersCount === 1) return NODE_AVATAR_SIZE
  if (partnersCount === 2) return NODE_AVATAR_SIZE * 0.8
  if (partnersCount === 3) return NODE_AVATAR_SIZE * 0.6
  // if (partnersCount === 4) return NODE_AVATAR_SIZE * 0.5
  // if (partnersCount === 5) return NODE_AVATAR_SIZE * 0.4
  // if (partnersCount === 6) return NODE_AVATAR_SIZE * 0.3
  // if (partnersCount === 7) return NODE_AVATAR_SIZE * 0.2
  // if (partnersCount === 8) return NODE_AVATAR_SIZE * 0.2
  // if (partnersCount === 9) return NODE_AVATAR_SIZE * 0.1

  return Math.max(30, NODE_AVATAR_SIZE / partnersCount)
}

const Partners = ({style, owner, editMode, treeId, subTreeOwner, readonly}) => {
  const partners = useMemo(
    () => owner.partners.sort(({id: idA}, {id: idB}) => idA - idB),
    [owner.partners]
  )
  const partnersCount = partners.length

  const size = useMemo(() => {
    return partnerHeightBasedOnCount(partnersCount)
  }, [partnersCount])

  const mergedStyle = useMemo(() => {
    const marginTop = (NODE_HEIGHT - partnersCount * size + (partnersCount - 1) * 0) / 2
    // console.log('### marginTop', marginTop, NODE_HEIGHT, partnersCount, size)

    return {
      ...style,
      height: size * partnersCount,
      marginTop: marginTop,
    }
  }, [style, size, partnersCount])

  if (!partnersCount) return null

  const withPadding = partnersCount > 1

  // console.log('### partners', partners)

  return (
    <>
      <Wrapper style={mergedStyle}>
        {partners.map((partner, index) => (
          <ErrorBoundary
            key={`${partner.id}_${index}`}
            fallback={<PartnerWithError $size={size}>Произошла ошибка</PartnerWithError>}
          >
            <Partner
              ownerId={owner.person_id}
              partner={partner}
              size={size}
              treeId={treeId}
              editMode={editMode}
              withPadding={withPadding}
              subTreeOwner={subTreeOwner}
              readonly={readonly}
              hideCult={partners.length > 2}
              hideSubTree={partners.length > 3}
            />
          </ErrorBoundary>
        ))}
      </Wrapper>
      {partners?.length === 1 ? (
        <NodeInfo style={style} leftOffset={-15} treeId={treeId} person={partners[0]} />
      ) : null}
    </>
  )
}

export default React.memo(Partners)

const Wrapper = styled.div`
  position: absolute;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const PartnerWithError = styled.div<{$size: number}>`
  width: ${({$size}) => $size}px;
  height: ${({$size}) => $size}px;
  object-fit: cover;
  border: solid 2px crimson;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  color: crimson;

  text-align: center;
`
