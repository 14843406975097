import React, {useMemo} from 'react'
import Select, {StylesConfig} from 'react-select'
import {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager'

import {useThemeMode} from '@_metronic/partials'

const LIGHT_COLOR = '#ffffff'
const DARK_COLOR = '#2b2b40'

type ThemedSelectProps = StateManagerProps & {style?: any}
const ThemedSelect = ({style, ...props}: ThemedSelectProps) => {
  const {mode} = useThemeMode()

  const themedStyle = useMemo(() => {
    const darkMode = mode === 'dark'

    const color = darkMode ? LIGHT_COLOR : DARK_COLOR
    const backgroundColor = darkMode ? DARK_COLOR : LIGHT_COLOR

    const colourStyles: StylesConfig = {
      control: (styles) => ({...styles, backgroundColor: backgroundColor}),
      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? backgroundColor
            : isFocused
            ? `${backgroundColor}CC`
            : backgroundColor,
          color: isDisabled ? `${color}80` : isSelected ? color : isFocused ? color : `${color}2C`,
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? backgroundColor
                : `${backgroundColor}60`
              : undefined,
          },
        }
      },
      input: (styles) => ({...styles}),
      placeholder: (styles) => ({...styles}),
      singleValue: (styles, {data}) => ({...styles}),
      menu: (styles) => ({...styles, backgroundColor}),
    }

    return colourStyles
  }, [mode])

  return <Select styles={{...themedStyle, ...style}} {...props} />
}

export default React.memo(ThemedSelect)
