import {useTreesStore} from '@store/trees'
import {TreeAvatarBorderType, TreeModel, TreePersistFieldNameEnum} from '@api/models'
import {LINE_FATNESS, NODE_AVATAR_SIZE, NODE_HEIGHT} from '@components/organisms/Tree/constants'

const DEFAULT_LINE_COLOR = '#FFFFFF'
const DEFAULT_LINE_WIDTH = LINE_FATNESS
const DEFAULT_LINE_SHADOW_WIDTH = 0
const DEFAULT_LINE_SHADOW_COLOR = '#FFFFFF'
const DEFAULT_AVATAR_BORDER_COLOR = '#FFFFFF'
const DEFAULT_AVATAR_BORDER_WIDTH = 2
const DEFAULT_AVATAR_BORDER_RADIUS = 100

const DEFAULT_PET_BORDER_COLOR = '#FFFFFF'
const DEFAULT_PET_BORDER_WIDTH = 2
const DEFAULT_PET_BORDER_RADIUS = 100
const DEFAULT_PET_BORDER_TYPE = TreeAvatarBorderType.solid

const DEFAULT_CULT_BORDER_COLOR = '#FFFFFF'
const DEFAULT_CULT_BORDER_WIDTH = 2
const DEFAULT_CULT_BORDER_RADIUS = 100
const DEFAULT_CULT_BORDER_TYPE = TreeAvatarBorderType.solid

const DEFAULT_AVATAR_BORDER_TYPE = TreeAvatarBorderType.solid
const DEFAULT_HEIR_LINE_COLOR = '#FFCC00'
const DEFAULT_HEIR_LINE_ENABLED = 'false'
const DEFAULT_PETS_DISPLAYING_ON_TREE_ENABLED = 'true'
const DEFAULT_FIRST_NAME_DISPLAYING_ENABLED = 'true'
const DEFAULT_LAST_NAME_DISPLAYING_ENABLED = 'true'
const DEFAULT_FULL_NAME_COLOR = '#FFFFFF'
const DEFAULT_DIED_STATUS_ENABLED = 'false'
const DEFAULT_DIED_STATUS_COLOR = '#DDDDDD'

/*
- цвет тени линиии
- Размытие тени
*/

export const DEFAULT_TREE_STYLES = {
  [TreePersistFieldNameEnum.lineColor]: DEFAULT_LINE_COLOR,
  [TreePersistFieldNameEnum.lineWidth]: DEFAULT_LINE_WIDTH,
  [TreePersistFieldNameEnum.lineShadowColor]: DEFAULT_LINE_SHADOW_COLOR,
  [TreePersistFieldNameEnum.lineShadowWidth]: DEFAULT_LINE_SHADOW_WIDTH,
  [TreePersistFieldNameEnum.avatarBorderColor]: DEFAULT_AVATAR_BORDER_COLOR,
  [TreePersistFieldNameEnum.avatarBorderWidth]: DEFAULT_AVATAR_BORDER_WIDTH,
  [TreePersistFieldNameEnum.avatarBorderRadius]: DEFAULT_AVATAR_BORDER_RADIUS,
  [TreePersistFieldNameEnum.avatarBorderType]: DEFAULT_AVATAR_BORDER_TYPE,
  [TreePersistFieldNameEnum.heirLineColor]: DEFAULT_HEIR_LINE_COLOR,
  [TreePersistFieldNameEnum.heirLineEnabled]: DEFAULT_HEIR_LINE_ENABLED,
  [TreePersistFieldNameEnum.petsDisplayingOnTreeEnabled]: DEFAULT_PETS_DISPLAYING_ON_TREE_ENABLED,
  [TreePersistFieldNameEnum.firstNameDisplayingEnabled]: DEFAULT_FIRST_NAME_DISPLAYING_ENABLED,
  [TreePersistFieldNameEnum.lastNameDisplayingEnabled]: DEFAULT_LAST_NAME_DISPLAYING_ENABLED,
  [TreePersistFieldNameEnum.fullNameColor]: DEFAULT_FULL_NAME_COLOR,
  [TreePersistFieldNameEnum.diedStatusDisplayingEnabled]: DEFAULT_DIED_STATUS_ENABLED,
  [TreePersistFieldNameEnum.diedStatusColor]: DEFAULT_DIED_STATUS_COLOR,

  [TreePersistFieldNameEnum.petBorderColor]: DEFAULT_PET_BORDER_COLOR,
  [TreePersistFieldNameEnum.petBorderWidth]: DEFAULT_PET_BORDER_WIDTH,
  [TreePersistFieldNameEnum.petBorderType]: DEFAULT_PET_BORDER_TYPE,
  [TreePersistFieldNameEnum.petBorderRadius]: DEFAULT_PET_BORDER_RADIUS,

  [TreePersistFieldNameEnum.cultBorderColor]: DEFAULT_CULT_BORDER_COLOR,
  [TreePersistFieldNameEnum.cultBorderWidth]: DEFAULT_CULT_BORDER_WIDTH,
  [TreePersistFieldNameEnum.cultBorderType]: DEFAULT_CULT_BORDER_TYPE,
  [TreePersistFieldNameEnum.cultBorderRadius]: DEFAULT_PET_BORDER_RADIUS,
}

const useTreeStyles = (treeId?: number): {[key in TreePersistFieldNameEnum]?: string | number} => {
  const {trees} = useTreesStore()
  const tree: TreeModel | null = typeof treeId !== 'undefined' ? trees[treeId] : null
  const persist = tree?.params?.persist

  return {
    [TreePersistFieldNameEnum.lineColor]: persist?.lineColor || DEFAULT_LINE_COLOR,
    [TreePersistFieldNameEnum.lineWidth]: persist?.lineWidth || DEFAULT_LINE_WIDTH,
    [TreePersistFieldNameEnum.lineShadowColor]:
      persist?.lineShadowColor || DEFAULT_LINE_SHADOW_COLOR,
    [TreePersistFieldNameEnum.lineShadowWidth]:
      persist?.lineShadowWidth || DEFAULT_LINE_SHADOW_WIDTH,

    [TreePersistFieldNameEnum.avatarBorderColor]:
      persist?.avatarBorderColor || DEFAULT_AVATAR_BORDER_COLOR,
    [TreePersistFieldNameEnum.avatarBorderWidth]:
      persist?.avatarBorderWidth || DEFAULT_AVATAR_BORDER_WIDTH,
    [TreePersistFieldNameEnum.avatarBorderRadius]:
      persist?.avatarBorderRadius || DEFAULT_AVATAR_BORDER_RADIUS,
    [TreePersistFieldNameEnum.avatarBorderType]:
      persist?.avatarBorderType || DEFAULT_AVATAR_BORDER_TYPE,

    [TreePersistFieldNameEnum.heirLineColor]: persist?.heirLineColor || DEFAULT_HEIR_LINE_COLOR,
    [TreePersistFieldNameEnum.heirLineEnabled]:
      persist?.heirLineEnabled || DEFAULT_HEIR_LINE_ENABLED,
    [TreePersistFieldNameEnum.petsDisplayingOnTreeEnabled]:
      persist?.petsDisplayingOnTreeEnabled || DEFAULT_PETS_DISPLAYING_ON_TREE_ENABLED,
    [TreePersistFieldNameEnum.firstNameDisplayingEnabled]:
      persist?.firstNameDisplayingEnabled || DEFAULT_FIRST_NAME_DISPLAYING_ENABLED,
    [TreePersistFieldNameEnum.lastNameDisplayingEnabled]:
      persist?.lastNameDisplayingEnabled || DEFAULT_LAST_NAME_DISPLAYING_ENABLED,
    [TreePersistFieldNameEnum.fullNameColor]: persist?.fullNameColor || DEFAULT_FULL_NAME_COLOR,
    [TreePersistFieldNameEnum.diedStatusDisplayingEnabled]:
      persist?.diedStatusDisplayingEnabled || DEFAULT_DIED_STATUS_ENABLED,
    [TreePersistFieldNameEnum.diedStatusColor]:
      persist?.diedStatusColor || DEFAULT_DIED_STATUS_COLOR,

    [TreePersistFieldNameEnum.petBorderColor]: persist?.petBorderColor || DEFAULT_PET_BORDER_COLOR,
    [TreePersistFieldNameEnum.petBorderWidth]: persist?.petBorderWidth || DEFAULT_PET_BORDER_WIDTH,
    [TreePersistFieldNameEnum.petBorderRadius]:
      persist?.petBorderRadius || DEFAULT_PET_BORDER_RADIUS,
    [TreePersistFieldNameEnum.petBorderType]: persist?.petBorderType || DEFAULT_PET_BORDER_TYPE,

    [TreePersistFieldNameEnum.cultBorderColor]:
      persist?.cultBorderColor || DEFAULT_CULT_BORDER_COLOR,
    [TreePersistFieldNameEnum.cultBorderWidth]:
      persist?.cultBorderWidth || DEFAULT_CULT_BORDER_WIDTH,
    [TreePersistFieldNameEnum.cultBorderRadius]:
      persist?.cultBorderRadius || DEFAULT_CULT_BORDER_RADIUS,
    [TreePersistFieldNameEnum.cultBorderType]: persist?.cultBorderType || DEFAULT_CULT_BORDER_TYPE,
  }
}

export default useTreeStyles
